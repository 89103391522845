import React from "react";
import { FC, ReactNode } from "react";
import { Question } from "../../repository/data";
export type SectionTitleProps = {
  title: string;
  id: string;
};

export const SectionTitle: FC<SectionTitleProps> = ({ title, id }) => {
  return (
    <div
      id={"SectionTitle" + id} key={"key"+id}
      className="flex flex-row justify-center py-1 bg-[#10691C] text-white"
    >
      {title}
    </div>
  );
};

export type FirstSectionProps = {
  id: string;
  Utility: any;
  handleChange: any;
};
export const FirstSection: FC<FirstSectionProps> = ({ id, Utility, handleChange }) => {
  return (
    <div id={"FirstSectionProps" + id} key={"key" + id}>
      <p id="id" className="font-bold mb-2">
        {Question[0].question}
      </p>
      <div className="justify-start flex flex-row  pl-4">
        {Question[0].data.map((item: string, index: number) => (
          <><div className="flex flex-row  items-center mb-1">
            <input
              key={index + "gender"}
              onChange={handleChange}
              type="radio"
              checked={Utility.currentValue("gender") === item}
              required={true}
              value={item}
              name={"gender"}
              id={index + "gender"}
            />
            <label className="mx-2" htmlFor={index + "gender"}>
              {item}
            </label>
          </div>

          </>
        ))}
      </div>
      {/* div */}
      <p id="id" className="font-bold">
        <br />
        {Question[1].question}
      </p>
      <div className="grid  grid-cols-2     ">
        {Question[1].data.map((item: string, index: number) => (
          <><div className="pl-4">
            <input
              key={index + "trancheAge"}
              checked={Utility.currentValue("trancheAge") === item}
              onChange={handleChange}
              type="radio"
              required={true}
              value={item}
              name={"trancheAge"}
              id={index + "trancheAge"}
            />
            <label className="ml-2 mr-4" htmlFor={index + "trancheAge"}>
              {item}
            </label>
          </div>

          </>
        ))
        }
      </div>
      {/* div */}
      <p id="id" className="font-bold">
        <br />
        {Question[2].question}
      </p>
      <div className="grid-cols-auto pl-5  py-3">
        {
          <select className="p-2" key={id + "key"} id={id + 'id'} value={Utility.currentValue("region")} onChange={handleChange} name={"region"}>
            {Question[2].data.map((region: any, index: any) => (<option id={index} value={region} > {region}</option>))}
          </select>
        }
      </div>
    </div>
  );
};

export type SecondSectionProps = {
  id: string;
  Utility: any;
  handleChange: any;
  otherRaison: boolean
}

export const SecondSection: FC<SecondSectionProps> = ({ id, Utility, handleChange, otherRaison }) => {
  return (
    <div id={"SecondSectionProps" + id} key={"key"+id}>
      <p id="id secondSection" className="font-bold">
        <div className="flex font-bold">4-<span id="id" className="font-bold mb-2 px-1">
          {Question[3].question}
        </span></div>
      </p>
      {/* grid grid-cols-2 */}
      <div className="grid grid-cols-2  gap-2 px-4">
        {Question[3].data.map((item: string, index: number) => (
          <>
            <div className=" " >
              <input
                key={index + "rubrique"}
                onChange={handleChange}
                type="checkbox"
                checked={Utility.formdata.rubrique.includes(item)}
                value={item}
                name={"rubrique"}
                id={index + "rubrique"}
              />
              <label className="mx-2" htmlFor={index + "rubrique"}>
                {item}
              </label>
            </div>
          </>
        ))}
      </div>
      {/* div */}
      <br />
      <p id="id" className="font-bold mb-2">
        <div className="flex font-bold">5-<span id="id" className="font-bold mb-2 px-1">
          {Question[4].question}
        </span></div>
      </p>
      <div className="flex flex-col pl-4  justify-start ">
        {Question[4].data.map((item: string, index: number) => (
          <>
            <div className="mb-1">
              <input
                key={index + "reason"}
                checked={Utility.formdata.reason.includes(item)}
                onChange={handleChange}
                type="checkbox"
                required={true}
                value={item}
                name={"reason"}
                id={index + "reason"}
              />
              <label className="ml-2 mr-4" htmlFor={index + "reason"}>
                {item}
              </label>
            </div>

          </>
        ))
        }
      </div>
      {/* div */}
      <div className={otherRaison ? "flex-col   py-3 mx-4 my-2" : "flex-col   py-3 mx-4 my-2 hidden"}   >
        <label className="text-xl font-medium " htmlFor={"otherRaison"}>Mentionnez</label>
        <textarea onChange={handleChange} placeholder=" Entrez votre texte ici..." className="flex w-full rounded-lg p-2 mt-2 resize-none focus:rounded-lg" name="otherRaison" value={Utility.currentValue("otherRaison")} id="otherRaison" />

      </div>

    </div>
  );
}
export type ThirtSectionProps = {
  id: string;
  Utility: any;
  handleChange: any;
}
export const ThirtSection: FC<ThirtSectionProps> = ({ id, Utility, handleChange }) => {
  return (
    <div id={id + ThirtSection} key={"key"+id}>
      <div className="flex font-bold">6-<span id="id" className="font-bold mb-2 px-1">
        {Question[5].question}
      </span></div>
      <div className="flex flex-row ">
        <input className="px-2 rounded-md w-20 mx-5" type="number" max='10' min='0' key={id + "key"} id={id + 'id'} value={Utility.currentValue("note")} onChange={handleChange} name={"note"} />
        <p className="font-semibold"> /10</p>
      </div>
      {/* div */}
      <br />

      <div className="flex font-bold">7-<span id="id" className="font-bold mb-2 px-1">
        {Question[6].question}
      </span></div>
      <div className="flex flex-row ">
        {/* <input className="px-2 rounded-md w-20 mx-2" type="number" max='10' min='0' key={id + "key"} id={id + 'id'} value={Utility.currentValue("note")} onChange={handleChange} name={"note"} /> */}
        <textarea  onChange={handleChange} placeholder=" Entrez votre texte ici..." className="rounded-lg flex w-full p-2 resize-none mx-4 mb-2 mt-2 focus:rounded-lg " name="Recommendations" id="Recommendations" value={Utility.currentValue("Recommendations")} />

      </div>
    </div>
  );
}
export type SectionProps = {
  children?: ReactNode | string;
  id: string;
};
const SectionForm: FC<SectionProps> = ({ children, id }) => {
  return (
    <div
      id={"SectionForm" + id} key={"key"+id}
      className="flex shadow-sm  flex-col bg-[#D9D9D9] rounded-lg m-4 py-4 p-2 "
    >
      {children}
    </div>
  );
};
export default SectionForm;



