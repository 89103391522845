import { FC, useRef } from "react";
import success from "../../asset/logo/success-icon-10.png";
import SectionForm from "../../components/section/section";
export const SuccessView: FC<any> = () => {
    const ref = useRef;
    return (

        <div className="px-4 flex flex-col h-screen  space-y-[130%]  md:px-[10%] lg:px-[30%] sm:px-[3%] ">
            <div className="flex flex-col ">
                <SectionForm key={ref.toString()} id={""}>
                    <div className="flex flex-row px-1 py-3 mx-4 items-center">
                        <img src={success} alt="" className=" text-[2rem] text-wrap text-center size-[45%]  md:size-[60%] md:text-2xl lg:size-[30%] xm:size-[10%] xm:text-sm " />
                        <div className="flex flex-col mx-3">
                            <span className="font-bold text-xl text-[#10691C] justify-center">Merci !</span>
                            <span className="text-wrap">Vos reponses ont été enregistrées avec succès!</span>
                        </div>
                    </div>
                </SectionForm>
                {/* <div onClick={()=>{window.close();}} className="cursor flex flex-row justify-end mt-5 px-4 " children={<span className="text-white cursor px-6 py-4 rounded-2xl bg-[#10691C]">Fermer </span>} /> */}
            </div>
            <div  className="flex flex-row  mb-0 bg-black text-white py-3 justify-center itetems-center" children={"Fourni par Nexah"} />
        </div>
    );
}
export default SuccessView;