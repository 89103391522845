import axios, { AxiosInstance, AxiosResponse } from 'axios';

const getAccessTokenFromLocalStorage =  () => {
    try {
      const storageKey = localStorage.getItem("persist:root") || '';
      const storageKeyToJS = JSON.parse(storageKey);
      const auth = JSON.parse(storageKeyToJS.auth);
      return auth.accessToken;
    } catch (error) {
     // console.log("Invalid token");
    }
  }
  



interface APIRequestProps {
    url: string;
    requestMethod?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    requestBody?: any;
}

export const APIRequest = async ({
    url,
    requestMethod = 'GET',
    requestBody = null
}: APIRequestProps): Promise<any> => {
    // const { auth } = useAuth();
    // const token = auth.accessToken;
    const token = await getAccessTokenFromLocalStorage();
    // console.log("Token :       ", token)

    
    const apiClient: AxiosInstance = axios.create({
        baseURL: "https://ms-mobile-news-survey-preprod.nexah.net/api/v1",
        headers: {
            "Content-type": "application/json",
        }
    });

    // Add an Axios interceptor to set the Authorization header
    apiClient.interceptors.request.use(
        (config) => {
            if (!config.url?.includes('/logine')) {
                // Add Bearer token to the Authorization header
                // const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Perform the request based on the request method
        switch (requestMethod.toUpperCase()) {
            case 'GET':
                return apiClient.get(url);
            case 'POST':
                return apiClient.post(url, requestBody);
            case 'PUT':
                return apiClient.put(url, requestBody);
            case 'DELETE':
                return apiClient.delete(url);
            default:
                throw new Error('Unsupported request method.');
        }
}

export const APIRequestWithFileUpload = async ({
    url,
    requestMethod = 'GET',
    requestBody = null
}: APIRequestProps): Promise<any> => {
    // const { auth } = useAuth();
    // const token = auth.accessToken;
    const token = await getAccessTokenFromLocalStorage();
    // console.log("Token :       ", token)

    const apiClient: AxiosInstance = axios.create({
        baseURL: "https://ms-mobile-news-survey-preprod.nexah.net/api/v1",
        headers: {
            "Content-type": "multipart/form-data",
        }
    });

    // Add an Axios interceptor to set the Authorization header
    apiClient.interceptors.request.use(
        (config) => {
            if (!config.url?.includes('/logine')) {
                // Add Bearer token to the Authorization header
                // const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            return config;
        },
        (error) => {
          //  console.log('API Interceptor error........................')
            return Promise.reject(error);
        }
    );

    // Perform the request based on the request method
    switch (requestMethod.toUpperCase()) {
        case 'GET':
            return apiClient.get(url);
        case 'POST':
            return apiClient.post(url, requestBody);
        case 'PUT':
            return apiClient.put(url, requestBody);
        case 'DELETE':
            return apiClient.delete(url);
        default:
            throw new Error('Unsupported request method.');
    }
}
// Example usage:
// const response = await apiRequest({ url: '/resource', requestMethod: 'GET' });
// console.log(response.data);
