import { APIRequest } from "./apiClient"

const Service = {
    senData: async (mark:string,age :string,gender:string,region:string,section:string[] ,reason:string[],recommendation:string,phone?:string)=>{
        const response = await APIRequest({url:"/client-create",requestMethod:"POST",requestBody:{
            "age":age,
            "gender":gender,
            "region":region,
            "section":section,
            "reason":reason,
            "mark":mark,
            "recommendation":recommendation,
            "phone":phone,
        }});
       // console.log("valeur de la response " + response);
        return response ;
    },
}
export default Service;