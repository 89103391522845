export type titre={
    avis: string,
    titre:string,
}
export type datas={
    question:string,
    data:string[],
}
export const Sections:string[] = [
  "A) IDENTIFICATION DU LECTEUR",
  "B) A PROPOS DU SERVICE",
  "C) AUTRES"
]
export const Titres:titre =  {avis : "VOTRE AVIS COMPTE POUR NOUS !", "titre":"Chers lecteurs, afin d'améliorer la qualité de service à votre égard, voici  quelques questions d'appréciations",};

export const Question :datas[] =  [
{"question":"1- Quel est votre genre ?","data":["Féminin","Masculin"]},
{"question":"2- Quel est votre âge ?","data":["20-39 ans","40-59 ans","60-69 ans"]},
{"question":"3- Quel est votre région d’habitation","data":["Nord","Est","Ouest","Littoral","Sud","Centre","Extrême-nord","Adamaoua","Nord-ouest","Sud-ouest",""]},
{"question":"Quelle(s) rubrique(s) du Cameroon Tribune trouvez-vous intéressante ?","data":["Société","Economie","Politique","Fait divers","International","Offres d’emploi","Communiqués","Appels d’offre"]},
{"question":"Quelle(s) raison(s) pourrait(ent) vous empêcher d’utiliser le service","data":["Mon solde est insuffisant","Je n’ai pas de connexion","Je reçois déjà le journal physique","J’ai un problème de réseau","J’ai oublié de me réabonner","Je n’ai pas reçu le SMS de rappel","Je n’ai pas le temps de lire le journal","Je ne reçois pas la UNE du jour","Je n’arrive pas à lire le journal à travers mon téléphone","Autre"],},
{"question":"Quelle note sur 10 attribuez-vous au service ?","data":["",]},
{"question":"Quelles recommandations proposez-vous pour notre service ?","data":["",]},
];


// const data = {

//  Titres(){return {avis : "VOTRE AVIS COMPTE POUR NOUS !", titre:"Chers lecteurs, afin d'améliorer la qualité de notre service, nous aimerions recevoir vos appréciations",};
//  },


//  Question():datas[] {  
//     return  [{"question":"1- Quel est votre genre ?","data":["Féminin","Masculin"]},
//         {"question":"2- Quel est votre âge ?","data":["20-39 ans","40-59 ans","60-69 ans"]},
//         {"question":"3- Quel est votre région d’habitation","data":["Nord","Est","Ouest","Litoral","Sud","Centre","Extreme-nord","Adamaoua","Nord-ouest","Sud-ouest"]},
//         {"question":"4- Quelle rubrique du Cameroon Tribune trouvez-vous intéressante ?","data":["Société","Economie","Politique","Fait divers","International","Offres d’emploi","Communiqués","Appels d’offre"]},
//         {"question":"5- Quelle raison pourrait vous empécher d’utiliser le service","data":["Mon solde est insuffisant","Je n’ai pas de connexion","Je reçois déjà le journal physique","J’ai un problème de réseau","J’ai oublié de me réabonner","Je n’ai pas reçu le SMS de rappel","Je n’ai pas le temps de lire le journal","Je ne reçois la la UNE du jour","Je n’arrive pas à lire le journal à travers mon téléphone","Autres"],},
//         {"question":"6- Quelle note sur 10 attribuez-vous au service ?","data":["",]},
//         {"question":"7- Quelles recommandations proposezvous pour notre service ?","data":["",]},];
//  }
// }
// export default data ;