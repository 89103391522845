import { FC, useEffect, useState } from "react";
import SectionForm, { FirstSection, SecondSection, SectionTitle, ThirtSection } from "../../components/section/section"
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../service/service";
import 'react-toastify/dist/ReactToastify.css';
import icone from "../../asset/logo/logo.png"
import { Titres, Sections } from "../../repository/data";
import ClipLoader from "react-spinners/ClipLoader";


const MainView: FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCorectForm, setisCorectForm] = useState(true);
  const [otherRaison, setotherRaison] = useState(false);
  const { phoneNumber } = useParams<string>();
  const navigate = useNavigate();
  const [listEmptyFile, setlistEmptyFile] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    gender: '',
    trancheAge: '',
    region: '',
    rubrique: ['aucune rubrique'],
    reason: ['aucune raison'],
    note: '',
    Recommendations: 'recommendations',
    otherRaison: '',
  });
 
  useEffect(() => {
    if (formData.reason.includes("Autre")) {
      setotherRaison(true);
    } else {
      setotherRaison(false);
    }
  }, [formData.reason]);



  const handleChange = (event: { target: { name: any; value: any; checked: any }; }) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "reason") {
      const { name, value, checked } = event.target;
      const updatedCheckboxOptions = checked
        ? [...formData.reason, value]
        : formData.reason.filter(option => option !== value);
      setFormData({ ...formData, [name]: updatedCheckboxOptions });
    }
    if (name === "rubrique") {
      const { name, value, checked } = event.target;
      const updatedCheckboxOption = checked
        ? [...formData.rubrique, value]
        : formData.rubrique.filter(option => option !== value);
      setFormData({ ...formData, [name]: updatedCheckboxOption });
    }
    if (name === "note") {
      const { name, value } = event.target; 
      if (value > 10){ setFormData({ ...formData, [name]: 10 });};
      if (value < 0){ setFormData({ ...formData, [name]: 1 });};
    }
    if (name ==="otherRaison") {
      const { name, value } = event.target;
      setFormData({ ...formData, ["otherRaison"]: value });
    }
  };

  const isValidDateValue = () => {
    if (formData.trancheAge.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setlistEmptyFile([...listEmptyFile, 'trancheAge']);
      setisCorectForm(false);
    }
    if (formData.gender.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setlistEmptyFile([...listEmptyFile, 'gender']);
      setisCorectForm(false);
    } if (formData.region.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setlistEmptyFile([...listEmptyFile, 'region']);
      setisCorectForm(false);
    }
    //  if (formData.note.trim() === '' ) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //   setlistEmptyFile( [...listEmptyFile ,'note'])
    //   setisCorectForm(false);}
    // } if (formData.reason.length <=1 ) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //   [...listEmptyFile ,'reason']
    //   setisCorectForm(false);
    // }
    // if (formData.rubrique.trim() === '' ) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //   [...listEmptyFile ,'rubrique']
    //   setisCorectForm(false);
    // }
    // if (formData.Recommendations.trim() === '' ) {
    //   setlistEmptyFile( [...listEmptyFile ,'Recommendations']);
    //   setisCorectForm(false);
    //  // isCorrect = true;
    // }

    // Si tous les champs sont remplis, retournez true pour permettre la soumission du formulaire
    return isCorectForm;
  }

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    setIsLoading(true);
    event.preventDefault();
    try{
       // eslint-disable-next-line @typescript-eslint/no-unused-expressions,
    formData.otherRaison !="" ? [...formData.reason,otherRaison]:null ;
      const response = await Service.senData(formData.note, formData.trancheAge, formData.gender, formData.region, formData.rubrique, formData.reason, formData.Recommendations, atob(typeof (phoneNumber) === "undefined" ? "" : phoneNumber));
      if (response["status"] === 200) {
        if (response.data["status"] === "OK" && response.data["message"] === "SUCCESS" && isValidDateValue()) {
          setIsLoading(false);
          // toast.success('Felicitation vos donnees ont ete enregistrés ', {
          //   position: "top-center",
          //   autoClose: 3000,
          //   closeOnClick: true,
          // })
          navigate('/success');
          console.log("redirect is ok");
        } else {
          toast.error("veuillez remplir tout les champs requis  ", {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
          });
          setIsLoading(false);
        }
      } else {
        toast.error('Verifiez votre connexion internet  et reessayez', {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
        });
        setIsLoading(false);
        //console.log('status  code  is not 200')
      }
    }catch (error) {
      await setTimeout(() => {
        toast.error('Verifiez votre connexion internet ', {
          position: "bottom-center", // Position du toast
          autoClose: 5000 // Durée d'affichage du toast en millisecondes
        });  setIsLoading(false);
      }, 5000);
    }
   

    // (mark:formData.note,age ,formData.trancheAge,gender:string,region:string,section:string ,reason:string,recommendation:string,phone:string);
  };
  const u = {
    formdata: formData,
    currentValue(v: string) {
      switch (v) {
        case "gender":
          return formData.gender;
          break;
        case "trancheAge":
          return formData.trancheAge;
          break;
        case "note":
          return formData.note;
          break;
        case "reason":
          return formData.reason;
          break;
        case "recommendations":
          return formData.Recommendations;
          break;
        case "region":
          return formData.region;
          break;
        case "rubrique":
          return formData.rubrique;
        case "otherRaison":
          return formData.otherRaison;
        default:
          break;
      }
    },
  };

  return (
    <div color={"#20a8d8"} className="flex overflow-scroll  w-screen h-screen bg-[#F4F5EF] flex-col  md:px-[10%] lg:px-[30%] sm:px-[5%]  ">
      <form onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="flex justify-center bg-[#10691C] h-[7%] mb-2">
          <div className="flex items-center px-4 justify-center">
            <img className="h-[100%] " src={icone} alt=""  />
          </div>
        </div>
        <div className=" flex flex-row bg-[#4aa4ee] text-white  py-2 font-bold justify-center items-center">
          {Titres.avis}
        </div>
        <div className="py-4 px-4">
          <span className="font-semibold"> {Titres.titre.substring(0, 14) + ""}</span>
          {Titres.titre.substring(14, Titres.titre.length)}
        </div>
        <SectionTitle key="keysectiontitle 0" id="1" title={Sections[0]} />
        <SectionForm key="keysectionform 0" id={"1"} ><FirstSection id={"1"} Utility={u} handleChange={handleChange} key={"keyfirstsection"} /> </SectionForm>
        <SectionTitle key="keysectiontitle 1" id="1" title={Sections[1]} />
        <SectionForm key="keysectionform 1" id={"2"} ><SecondSection otherRaison={otherRaison} id={"2"} Utility={u} handleChange={handleChange} key={"keysecondsection"} /> </SectionForm>
        <SectionTitle key={"keysecondtitle"} title={Sections[2]} id={"3"} />
        <SectionForm id={"3"} key={"keysectionform 2"} ><ThirtSection id={"3"} Utility={u} handleChange={handleChange} key={"keysectionform2"} /> </SectionForm>
        <br />
        <div className="flex justify-end space-x-12  px-9 items-center">
          {/* <p onClick={() => isLoading ? setIsLoading(false) : null} className="text-[#10691C] cursor-pointer">Annuller</p> */}
          <div onClick={handleSubmit} className="flex cursor-pointer  bg-[#10691C] text-white rounded-xl px-6 py-3 items-center" ><span className="px-2" children={"Soumettre"}/><ClipLoader className="px-1" loading={isLoading} color='white' /></div>
        </div>
      </form>
      <div className="flex flex-row  mb-0 bg-black text-white py-3 justify-center itetems-center">Fourni par Nexah</div>
    </div>
  );
}
export default MainView;
