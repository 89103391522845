import React from 'react';
import './App.css';

import { BrowserRouter ,Route, Routes } from 'react-router-dom';
import {  SuccessView } from "./pages/success/index";
import MainView from './pages/main';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/:phoneNumber" element={<MainView />}/>
      <Route path="/success" element={<SuccessView />} />

      </Routes>
    </BrowserRouter>
  );
}

      